// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';

// styles
import {
  magnifyStyle,
  unbreakableStringStyle,
  unstyledLinkStyle,
} from '@styles/global';

// images
import IconArrow from '@images/IconArrow';

// components
import Layout from '@components/Layout';
import Button from '@components/Button';

// views
import IntroSection from '@views/BusinessPageView/IntroSection';
import ProductsSection from '@views/BusinessPageView/ProductsSection';
import ProjectSection from '@views/BusinessPageView/ProjectSection';
import HeroSection from '@views/BusinessPageView/HeroSection';
import SEO from '@src/components/SEO';

const BusinessesPage = ({ data }) => {
  const {
    products: { nodes: products },
    categories: { nodes: categories },
  } = data;

  const langLinks = [{ langKey: 'fr', href: '/entreprises' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        title='Businesses'
        description='Trust Permafil to manufacture and install quality storage products for your residential and commercial projects.'
      />

      <HeroSection>
        <h1>
          Businesses<span css={hideVisually()}> - </span>
          <small>
            Products and services
            <br />
            for professionals
          </small>
        </h1>
      </HeroSection>

      <IntroSection>
        <h2 css={hideVisually}>Why choosing Permafil</h2>

        <p css={magnifyStyle}>
          Choosing the Permafil team means taking advantage of impeccable,
          professional service during each stage of your project, from the
          initial idea to completion.
        </p>

        <p>
          Our top priority is to achieve nothing less than your success. Our
          dedicated experts ensure that the products you select and their
          installation meet your expectations in terms of quality, and within
          your timeframes. Whether you need wire shelf installation or wire
          closet shelf installations, we’ve got you covered!
        </p>

        <p>Our turnkey services guarantees you peace of mind.</p>
      </IntroSection>

      <ProductsSection products={[...categories, ...products]} />

      <ProjectSection>
        <h2>Are you in charge of a project?</h2>

        <p className='subTitle'>
          Contact us{' '}
          <a
            href='tel:+18004631434'
            css={[unbreakableStringStyle, unstyledLinkStyle]}
          >
            1-800-463-1434
          </a>
        </p>

        <Button
          outlined
          renderIcon={<IconArrow />}
          to='/en/contact-us'
          tag='link'
        >
          Contact us
        </Button>

        <p>
          A representative from your region will be happy to help you choose
          Permafil products and services that will best meet your needs. Once
          you have placed your order, your project will be completed by one of
          our qualified experts, who will ensure your complete satisfaction
          until the final installation.
        </p>

        <p>We look forward to work with you!</p>
      </ProjectSection>
    </Layout>
  );
};

BusinessesPage.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default BusinessesPage;

export const query = graphql`
  query businessPageQuery {
    products: allSanityProduct(
      filter: {
        targetAudience: { eq: "company" }
        localization: { locale: { eq: "en" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        title
        categories {
          id
        }
        localization {
          locale
        }
      }
    }

    categories: allSanityProductCategory(
      filter: {
        localization: { locale: { eq: "en" } }
        targetAudience: { eq: "company" }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        targetAudience
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }
  }
`;
